.wrapper {
    width: 40%;
    border: 1px solid #f0f0f0;
    margin-bottom: 10px;

    p {
        margin-bottom: 0;
    }

    .titleWrapper {
        padding: 10px 20px;
        background-color: #f0f0f0;
    }

    .contentWrapper {

        .content {
            padding: 10px 20px;
            border-bottom: 0.5px solid gray;

            .answer {
                color: #6B12DE;
            }
        }

        .content:last-child {
            border-bottom: none;
        }
    }
}

@media (min-width: 640px) {}

@media (min-width: 783px) {}

@media (min-width: 1024px) {}

@media (min-width: 1280px) {}

@media (min-width: 1536px) {}