.wrapper {
    border-radius: 5px;
    width: 100%;
    margin: auto 20px;
    padding: 30px;
    margin-top: 20px;

    h1 {
        font-size: 25px;
        margin-bottom: 10px;
    }
}

@media (min-width: 640px) {
    
}

@media (min-width: 768px) {
    .wrapper {
        max-width: 450px;
        min-width: 450px;
        margin: auto;
        padding: 20px;
        margin-top: 20px;
    
        h1 {
            font-size: 30px;
        }
    }
}

@media (min-width: 1024px) {
}