@import "antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Mulish';
	src: url('../../public/fonts/mulish/Mulish-Regular.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Mulish';
	src: url('../../public/fonts/mulish/Mulish-Medium.ttf');
	font-display: swap;
	font-weight: 500;
}

@font-face {
	font-family: 'Mulish';
	src: url('../../public/fonts/mulish/Mulish-SemiBold.ttf');
	font-display: swap;
	font-weight: 600;
}

@font-face {
	font-family: 'Mulish';
	src: url('../../public/fonts/mulish/Mulish-Bold.ttf');
	font-display: swap;
	font-weight: bold;
}

body {
	font-family: 'Mulish';
}

.ant-form-item-explain-error {
    margin-bottom: 5px;
}

/*--------------------------------------------------------------
# Antd Pro Components
--------------------------------------------------------------*/
.ant-pro-layout .ant-pro-base-menu.ant-menu:not(.ant-menu-horizontal).ant-menu-item:hover,
.ant-pro-layout .ant-pro-base-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active,
.ant-pro-layout .ant-pro-base-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
    background-color: rgba(0, 0, 0, 0.06);
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0 !important;
}

/*--------------------------------------------------------------
# React Quill
--------------------------------------------------------------*/
.quill {
	font-family: 'Mulish' !important;
}

.ql-toolbar {
	font-family: 'Mulish' !important;
}

.ql-container {
	font-family: 'Mulish' !important;
}