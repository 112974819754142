.wrapper {
    :global {
        .select__control {
            background: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            min-height: auto;
        }

        .select__control:hover {
            border: 1px solid #40a9ff;
        }

        .select__control--is-focused {
            border: 1px solid #40a9ff;
            box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        }

        .select__value-container {
            line-height: 1.5715;
            padding: 4px 11px 4px;
            font-size: 14px;
        }

        .select__input-container {
            margin: 0px;
            padding: 0px;
        }

        .select__indicator {
            height: 100%;
            padding: 0px 8px;
            align-items: center;
        }

        .select__menu {
            z-index: 500;
        }
    }
}

.hasError {
    :global {
        .select__control {
            border: 1px solid #ff4d4f;
        }

        .select__control:hover {
            border: 1px solid #ff4d4f;
        }

        .select__control--is-focused {
            border: 1px solid #ff4d4f;
            box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
        }
    }
}